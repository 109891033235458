<template>
    <div>
        <report :title="$route.name" :parameters="parameters" :loading="loading" @refresh="getReportData()" @exportReport="exportReport">
            <template v-slot:content>
                <v-row>
                    <v-col cols="5" offset="7">
                        <v-text-field v-model="filter" append-icon="mdi-magnify" label="Filter" single-line hide-details clearable></v-text-field>
                    </v-col>
                </v-row>
                
                <v-data-table :headers="headers" :items="rptData" :search="filter" :items-per-page="200" :single-expand="singleExpand" :expanded.sync="expanded" item-key="foreignId" show-expand>
                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length" class="innerData">

                            <v-simple-table dense>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">Style #</th>
                                            <th class="text-left">Description</th>
                                            <th class="text-left">Size</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(data, i) in item.items" :key="i">
                                            <td>{{ data.style }}</td>
                                            <td>{{ data.productName }}</td>
                                            <td>{{ data.size }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>

                        </td>
                    </template>

                    <template v-slot:body.append="{ headers, items }">
                        <tr class="totals" v-if="items.length > 0">
                            <td v-for="(col, i) in headers" :key="i" :class="col.align == 'right' ? 'text-right subtitle-2' : 'subtitle-2'">
                                {{ i == 1 ? 'Count: ' + summarize( col, i ) : summarize( col, i ) }}
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </template>
        </report>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Report from '@/components/Report.vue';
import { API } from '@/inc/api';
import { _st } from '@/softech';

export default {
    data: () => ({
        parameters: [{
            type: 'select', 
            key: 'selectStore', 
            label: 'Origin store', 
            value: { id: '%', text: 'All' }, 
            visible: true, 
            icon: '',
            options: [
                { id: '%', text: 'All' }, 
                { id: 3, text: '65 de Infanteria' }, 
                { id: 6, text: 'Arecibo' }, 
                { id: 9, text: 'Bayamon' }, 
                { id: 4, text: 'Caguas' }, 
                { id: 5, text: 'Catano' }, 
                { id: 8, text: 'Mayaguez' }, 
                { id: 7, text: 'Ponce' }, 
                { id: 2, text: 'San Juan' },  
                { id: 1, text: 'Web' },
                { id: 10, text: 'Winter Park' } 
            ]
        }],
        headers: [
            { text: 'Use Date', value: 'useDate', sortable: true, summarizeFn: 'count', formatter: 'na' },
            { text: 'Due Back Date', value: 'returnDate', sortable: true },
            { text: 'Order Num', value: 'foreignId', sortable: true },
            { text: 'Customer', value: 'customerName', sortable: true },
            { text: 'Order Status', value: 'orderStatus', sortable: true },
        ],
        rptData: [],
        filter: '',
        loading: false,

        expanded: [],
        singleExpand: false,
    }),
    computed: {
        ...mapGetters({
            user: 'auth/user'
        })
    },
    created() {
        if( !_st.isEmpty( this.user.privileges['reportAccess'] ) )
            this.parameters[0].visible = false;
    },
    methods: {
        async getReportData() {
            let api = new API();

            try {
                this.loading = true;
                let res = await api.get(`/admin/rpt/rentlatereturns/${encodeURI( this.parameters[0].value.id )}`);
                this.loading = false;
                
                if( res.data.status === false ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' )
                    return;
                }

                this.rptData = res.data.data;
            }
            catch(error) {
                this.loading = false;
                this.mxShowMessage( error, 'error' );
            }
        },
        summarize( col ) {
            let result = 0;

            if( this.rptData.length == 0 )
                return;

            // Summarize data
            if( col.summarizeFn == 'sum' ) {
                result = this.rptData.reduce( function(a, b) {
                    return parseFloat(a) + parseFloat(b[col.value] == null ? 0 : b[col.value]);
                }, 0);
            }
            else if( col.summarizeFn == 'count' ) {
                result = this.rptData.length;
            }

            // Apply formatter
            switch( col.formatter ) {
                case 'money': return this.formatMoney( result );
                case 'na': return result;
            }
        },
        exportReport( format ) {
            if( format == 'pdf' ) this.generatePdf();
            else if( format == 'csv' ) this.exportCsv();
        },
        generatePdf() {
            let data = this.createPdfArray( this.headers, this.rptData );
            this.exportPdf( this.headers, data );
        },
        exportCsv() {
            let csvContent = "data:text/csv;charset=utf-8,";
            csvContent += this.createCsvArray(this.headers, this.rptData).map(item => item.join(',')).join("\n");

            var encodedUri = encodeURI(csvContent.replace(/#/g, 'Num'));
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", encodeURI( this.$route.name ) + ".csv");
            document.body.appendChild(link); // Required for FF

            link.click(); // This will download the data file named "my_data.csv".
        },
    },
    components: {
        Report
    }
}
</script>

<style scoped>
tr.totals td {
    background-color: #ececec;
    height: auto;
    border-top: 3px double #000;
}
td.innerData, td.innerData > * {
    background-color: #ececec;
}
</style>